.contact-banner-item {
  height: 80vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
}
.contact-banner-item::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-image: radial-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.6));
  background-size: 100% 100%;
}
.contact-banner .container {
  height: 100%;
}
.contact-banner-content {
  position: relative;
  height: 100%;
}
.contact-banner-content .m-box {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 3;
  transform: translateY(-50%);
}
.contact-banner-content .m-text {
  font-size: 20px;
  line-height: 1.666;
  text-align: center;
  color: #fff;
  opacity: 0;
  transform: translateY(-20px);
  transition: all 0.6s ease 0.5s;
}
.contact-banner-content p {
  text-indent: 40px;
}
.contact-banner .swiper-slide-active .contact-banner-content .m-text {
  opacity: 1;
  transform: translateY(0);
}
.contact-banner .swiper-pagination {
  width: auto;
  right: 15px;
  left: auto;
  bottom: 40px;
}
.contact-banner .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transition: all 0.4s;
}
.contact-banner .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 6px;
}
.contact-banner .swiper-pagination-bullet-active {
  background: #fff;
  animation-name: none;
}
.contact-us {
  background: #f9f9f9;
  padding: 1rem 0 0.5rem;
}
.contact-title {
  text-align: center;
  position: relative;
  margin-bottom: 0.8rem;
}
.contact-title h3 {
  font-size: 36px;
  font-weight: 600;
}
.contact-title h4 {
  text-transform: uppercase;
  font-size: 40px;
  font-weight: 500;
  opacity: 0.05;
  position: absolute;
  top: 10px;
  left: 50%;
  width: 100%;
  text-align: center;
  transform: translateX(-50%);
}
.contact-us-item {
  background: #fff;
  border-radius: 0.2rem;
  overflow: hidden;
  border: 1px solid #e5e6e9;
  margin-bottom: 0.4rem;
  transition: all 0.6s ease;
}
.contact-us-item .m-cont {
  padding: 0.55rem 0;
  text-align: center;
}
.contact-us-item .m-img {
  overflow: hidden;
}
.contact-us-item .m-img img {
  width: 100%;
  transition: all 0.8s;
}
.contact-us-item .m-tit {
  font-size: 0.36rem;
}
.contact-us-item .m-des {
  font-size: 0.22rem;
  color: #676c7a;
  margin-top: 0.1rem;
}
.contact-bottom {
  margin-top: 1.2rem;
}
.contact-map {
  height: 4.4rem;
  border-radius: 20px;
  overflow: hidden;
}
.constact-info {
  margin-top: 0.4rem;
}
.constact-info li {
  padding: 0.25rem 0;
}
.constact-info .m-left {
  float: left;
}
.constact-info .m-left img {
  width: 0.36rem;
}
.constact-info .m-right {
  margin-left: 0.63rem;
}
.constact-info .m-tit {
  font-size: 0.28rem;
}
.constact-info .m-des {
  font-size: 0.28rem;
  color: #676c7a;
  margin-top: 0.15rem;
}
.constact-info a {
  transition: all 0.3s;
}
.constact-info a:hover {
  color: #38ac9f;
}
@media (min-width: 992px) {
  .contact-banner-content .m-box {
    width: 5.86rem;
  }
  .contact-banner .swiper-pagination {
    right: 30px;
    bottom: 30px !important;
  }
  .contact-banner .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
  }
  .contact-banner .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 0;
  }
  .contact-banner-content .m-text {
    font-size: 16px;
    text-align: left;
  }
  .contact-banner-content p {
    text-indent: 0.6rem;
  }
  .contact-title h3 {
    font-size: 0.6rem;
  }
  .contact-title h4 {
    font-size: 0.8rem;
    top: 0.07rem;
  }
  .contact-top .row {
    margin: 0 -0.35rem;
  }
  .contact-top .col-xs-12 {
    padding: 0 0.35rem;
  }
  .contact-us-item {
    /* margin-bottom: 0; */
  }
  .contact-us-item .m-cont {
    min-height: 2rem;
  }
  .contact-us-item:hover .m-img img {
    transform: scale(1.08);
  }
  .contact-us-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  }
  .constact-info {
    margin-top: 0;
  }
}
@media (min-width: 1240px) {
  .contact-top .row {
    margin: 0 -0.55rem;
  }
  .contact-top .col-xs-12 {
    padding: 0 0.55rem;
  }
  .contact-bottom .row {
    margin: 0 -0.58rem;
  }
  .contact-bottom .col-sm-12 {
    padding: 0 0.58rem;
  }
}
