.ant-btn-primary {
  background-color: white;
  color: #35b5d3;
  border-color: white;
}
.swiper-container {
  --swiper-theme-color: #ff6600;
  --swiper-pagination-color: #00ff33; /* 两种都可以 */
}
.bottom-box {
  cursor: pointer;
}
.firsttitle {
  position: absolute;
  top: 70px;
  color: white;
  text-align: center;
  padding: 0 50px 0 50px;
}

.animate-bounce-down1 {
  cursor: pointer;
  -webkit-animation: bounce-down 1s linear infinite;
  animation: bounce-down 1s linear infinite;
  margin: 0 auto;
  margin-top: 30px;
  margin-left: 20px;
}
@keyframes bounce-down {
  25% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
.textLeft {
  -webkit-animation: textLeft 1s linear;
  animation: textLeft 1s linear;
}
@keyframes textLeft {
  0% {
    opacity: 0;
    transform: translateY(-20px);
    transition: all 0.6s ease 0.5s;
  }
}

.bianzu {
  width: 6rem;
  display: block;
  -webkit-transition: all 0.8s;
  -o-transition: all 0.8s;
  transition: all 0.8s;
}
.imglist {
  display: flex;
  margin-top: 0.6rem;
  justify-content: space-between;
}
.bianzu:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.chanpin {
  font-size: 0.24rem;
  color: white;
  margin-top: 0.5rem;
}
.chanpin li {
  margin-bottom: 0.2rem;
}
.chanpin li {
  list-style: none;
}
.chanpin li div:first-child {
  font-weight: bolder;
  font-size: 0.2rem;
}
.chanpin li div:last-child {
  opacity: 0.5;
  font-size: 0.16rem;
}
.swiper-pagination-bullet .swiper-pagination-bullet-active {
  background-color: white !important;
}
.swiper-pagination-bullet {
  background-color: white !important;
  margin-left: 0.35rem !important;
}
:root {
  --swiper-navigation-color: var(--swiper-theme-color) !important;
}
:root {
  --swiper-theme-color: white !important;
}
.swiper-pagination-bullet-active {
  width: 0.08rem;
  height: 0.08rem;
  display: inline-block;
  border-radius: 0.04rem;
  background: #000;
  opacity: 0.2;
  animation-name: count;
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes count {
  0% {
    width: 0.1rem;
    border-radius: 0.04rem;
  }
  25% {
    width: 0.3rem;
    border-radius: 0.04rem;
  }
  50% {
    width: 0.5rem;
    border-radius: 0.04rem;
  }
  75% {
    width: 0.6rem;
    border-radius: 0.04rem;
  }
  100% {
    width: 0.7rem;
    border-radius: 0.04rem;
  }
}

.pingju {
  flex: 1;
}
.firstbox {
  padding: 0 0.8rem 0 0;
  text-align: end;
}
.secondbox {
  display: flex;
  justify-content: space-around;
  padding-right: 1rem;
}

.dingweistyle {
  width: 2rem;
}
.map {
  width: 14.67rem;
}
.dingweitext {
  position: absolute;
  width: 2rem;
  height: 2rem;
  left: 0rem;
  top: 0.5rem;
  font-weight: bold;
  text-align: center;
}
.dingweitext p {
  font-size: 0.16rem;
  color: white;
  margin-top: 0.15rem;
  cursor: pointer;
}
.firstdingwei {
  position: absolute;
  top: 1.5rem;
  left: 2rem;
}
.updown {
  -webkit-animation: bounce-down 1.6s linear infinite;
  animation: bounce-down 1.6s linear infinite;
}
.backbox2 {
}
@-webkit-keyframes bounce-down {
  25% {
    -webkit-transform: translateY(-4px);
  }
  50%,
  100% {
    -webkit-transform: translateY(0);
  }
  75% {
    -webkit-transform: translateY(4px);
  }
}
@keyframes bounce-down {
  25% {
    transform: translateY(-4px);
  }
  50%,
  100% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(4px);
  }
}

@media (min-width: 1000px) {
  html {
    font-size: 65px;
  }
}
@media (min-width: 1280px) {
  html {
    font-size: 70px;
  }
}
@media (min-width: 1440px) {
  html {
    font-size: 75px;
  }
}
@media (min-width: 1680px) {
  html {
    font-size: 86px;
  }
}
@media (min-width: 1920px) {
  html {
    font-size: 100px;
  }
}
.bottombox {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.backbox2 {
  background-color: #00dcec;
  width: 1.8rem;
  height: 3.3rem;
  opacity: 20%;
  margin-top: -1rem;
  z-index: -2;
  position: absolute;
  margin-top: -4.3rem;
  margin-left: 4.3rem;
}
.backbox3 {
  background-color: #00dcec;
  width: 4rem;
  height: 1.3rem;
  opacity: 20%;
  margin-top: -1rem;
  z-index: -2;
  position: absolute;
  /* margin-top: -4.3rem; */
  margin-left: 1.3rem;
}
.righttext {
  text-align: center;

  /* padding:3rem ; */
}

.gsjjleftbox {
  text-align: center;
  position: relative;
}
.smallimg {
  width: 40%;
  margin-top: -4rem;
  display: block;
  margin-left: 2rem;
}
.smallliucheng {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 50px;
}
.newSwiper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0.1rem;
}
.righttitle {
  font-size: 1rem;
}
.sytopimg {
  /* background: url(../images/samllsyimg.png) no-repeat center center; */
  background-size: 100% 100%;
  width: 100vw;
  height: 42vh;
}
.sytext1 {
  color: white;
  font-size: 22px;
  font-weight: 600px;
}
.sytext2 {
  color: white;
  font-size: 16px;
  margin-top: 50px;
}
.sytext3 {
  color: white;
  font-size: 0.12rem;
  margin-top: 50px;
  font-weight: 400;
}
.zixunback {
  height: 6rem;
  background: url('../images/home/top-bg.jpg') no-repeat bottom center;
  background-size: cover;
}
.syimg {
  width: 100%;
}
@media (min-width: 992px) {
  .zixunback {
    height: 11.5rem;
  }
  .syimg {
    width: 100%;
  }
}
.downbottom {
  margin-top: 20px;
  text-align: center;
}
.gsjj {
  /* margin-top: -50px; */
  width: 100%;
}
.smallimg {
  width: 40%;
  margin-top: -50px;
  display: block;
  margin-left: 50px;
}
.backbox2 {
  display: none;
}
.righttitle {
  font-size: 16px;
}
.righttext {
  /* padding: 20px; */
  margin-top: 1rem;
}

.bianzu {
  width: 20%;
}
.liucheng {
  display: none;
}
.mapstyle {
  padding: 50px;
  margin-top: 0 50px 50px 50px;
}
.maptitle p:nth-of-type(1) {
  font-size: 0.6rem;
  font-weight: bolder;
}
.maptitle p:nth-of-type(2) {
  font-size: 12px;
  /* font-weight: bolder; */
  color: #9b9ea7;
}
.mapdetails {
  display: none;
}
.smallmap {
  width: 100%;
  text-align: center;
  display: block;
}
.rightclass {
  display: none;
}
.swiperstyle {
  display: none;
}
.totalbottom {
  width: 100%;
}
.totalbottom-top {
  position: relative;
}
.totalbottom-top-text1 {
  position: absolute;
  font-size: 20px;
  opacity: 12%;
  text-align: center;
  bottom: 10px;
  width: 100%;
}
.totalbottom-top-text2 {
  position: absolute;
  font-size: 16px;
  color: white;
  text-align: center;
  bottom: 11px;
  width: 100%;
}
.bottom-row {
  width: 100%;
  padding: 0 16px;
}
.bottom-box {
  float: left;
  width: 50%;
  float: left;
  padding: 0 5%;
  position: relative;
  transition: all 0.6s ease;
}
.bottom-box img {
  transition: all 0.8s;
}
.bottom-box:hover img {
  transform: scale(1.08);
}
.bottomimg {
  width: 100%;
}
.bottomdown {
  background-image: linear-gradient(to right, #38c7bb, #36adde);
  background-size: 100% 100%;
  width: 100%;

  overflow: auto;
  margin-top: -1px;
}

.bottomtext-top {
  font-size: 14px;
  padding: 0 5%;
  color: white;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.bottomtext-bottom {
  font-size: 12px;
  text-align: end;
  padding: 0 5%;
  color: white;
}
.is-show {
  display: none;
}
.noswiper {
  background-image: linear-gradient(to bottom, #38c7bb, #36adde);

  width: 100%;
  height: 100%;
  display: block;
  background-size: cover;
}
.newleft1 {
  z-index: 10;
  margin-left: 0.5rem;
  text-align: center;
}
.bottomsstyle {
  width: 150px;
  height: 30px;
  background-color: white;
  color: #35b5d3;
  padding: 2px;
  line-height: 30px;
  text-align: center;
  border-radius: 15px;
  text-align: center;
  cursor: pointer;
}
.bottomsstyle:hover {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.righttext {
  padding: 0 15px;
}
.bottomtext {
  position: absolute;
  bottom: 0;
  width: 80%;
  font-weight: 600;
  margin-bottom: 14px;
}
.sanjia {
  width: 6.02rem;
}

.li-style-none {
  list-style: none !important;
}
@media (min-width: 500px) {
  .sytext1 {
    margin-top: 50px;
  }
}
/* .sanjia{
  width: 8.02rem
} */
@media (min-width: 988px) {
  .map {
    width: 10rem;
  }
  .firstdingwei {
    position: absolute;
    top: 0.3rem;
    left: 2rem;
  }

  .seconddingwei {
    position: absolute;
    top: -1rem;
    left: 8rem;
  }
  .thirddingwei {
    position: absolute;
    top: 0.9rem;
    left: 6.5rem;
  }
  .fourthdingwei {
    position: absolute;
    top: 2.8rem;
    left: 4rem;
  }
  .fifthdingwei {
    position: absolute;
    top: 5rem;
    left: 5rem;
  }

  .sixthdingwei {
    position: absolute;
    top: 3.5rem;
    left: 6.4rem;
  }
  .seventhdingwei {
    position: absolute;
    top: 3rem;
    left: 8.2rem;
  }
}
/* @media (min-width: 1080px){
  .map{
    width: 15rem;
  }
  .firstdingwei{
    position: absolute;
    top: 1.3rem;
    left: 3rem;
  }
 
  .seconddingwei{
    position: absolute;
    top: -.5rem;
    left: 12.5rem;
  }
  .thirddingwei{
    position: absolute;
    top: 2rem;
    left:10rem;
  }
  .fourthdingwei{
    position: absolute;
    top: 5.5rem;
    left: 6rem;
  }
  .fifthdingwei{
    position: absolute;
    top: 8rem;
    left: 8rem;
  }
  
  .sixthdingwei{
    position: absolute;
    top: 6rem;
    left: 9.5rem;
  }
  .seventhdingwei{
    position: absolute;
    top: 5rem;
    left: 12rem;
  }
} */
@media (min-width: 1200px) {
  .sanjia {
    width: 10rem;
  }
}
/* @media (min-width: 1200px){
  .sanjia{
    width: 10rem
  }
  .map{
    width: 15.67rem;
  }
  .firstdingwei{
    position: absolute;
    top: 1.5rem;
    left: 3.5rem;
  }
 
  .seconddingwei{
    position: absolute;
    top: 2rem;
    left: 10.5rem;
  }
  .thirddingwei{
    position: absolute;
    top: 0rem;
    left: 13.4rem;
  }
  .fourthdingwei{
    position: absolute;
    top: 5.8rem;
    left: 6.5rem;
  }
  .fifthdingwei{
    position: absolute;
    top: 8.5rem;
    left: 8.5rem;
  }
  
  .sixthdingwei{
    position: absolute;
    top: 6.5rem;
    left: 10rem;
  }
  .seventhdingwei{
    position: absolute;
    top: 5.5rem;
    left: 12rem;
  }
} */
@media (min-width: 1280px) {
  .sanjia {
    width: 8rem;
  }
}
/* @media (min-width: 1280px){
  .sanjia{
    width: 11rem
  }
  .map{
    width: 16.67rem;
  }
  .firstdingwei{
    position: absolute;
    top: 2rem;
    left: 3.5rem;
  }
 
  .seconddingwei{
    position: absolute;
    top: 2.5rem;
    left: 11rem;
  }
  .thirddingwei{
    position: absolute;
    top: 0rem;
    left: 14rem;
  }
  .fourthdingwei{
    position: absolute;
    top: 6rem;
    left: 7rem;
  }
  .fifthdingwei{
    position: absolute;
    top:9rem;
    left: 9rem;
  }
  
  .sixthdingwei{
    position: absolute;
    top: 7rem;
    left: 11rem;
  }
  .seventhdingwei{
    position: absolute;
    top: 5.5rem;
    left: 13rem;
  }
}

@media (min-width: 1400px){
  .sanjia{
    width: 12rem
  }
  .map{
    width: 18.67rem;
  }
  .firstdingwei{
    position: absolute;
    top: 2.5rem;
    left: 4.3rem;
  }
  .seconddingwei{
    position: absolute;
    top: 3rem;
    left: 12.5rem;
  }
  .thirddingwei{
    position: absolute;
    top: 0rem;
    left: 16rem;
  }
  .fourthdingwei{
    position: absolute;
    top: 7.5rem;
    left: 8rem;
  }
  .fifthdingwei{
    position: absolute;
    top:10rem;
    left: 10rem;
  }
  
  .sixthdingwei{
    position: absolute;
    top: 8.5rem;
    left: 12rem;
  }
  .seventhdingwei{
    position: absolute;
    top: 7rem;
    left: 14rem;
  }
}
@media (min-width: 1500px){
  .sanjia{
    width: 8.02rem
  }
  .map{
    width: 12.67rem;
  }
  .firstdingwei{
    position: absolute;
    top: 1rem;
    left: 2.5rem;
  }
  .seconddingwei{
    position: absolute;
    top: -1rem;
    left: 10.5rem;
  }
  .thirddingwei{
    position: absolute;
    top: 1rem;
    left: 8rem;
  }
  .fourthdingwei{
    position: absolute;
    top: 4rem;
    left: 5rem;
  }
  .fifthdingwei{
    position: absolute;
    top:6rem;
    left: 6.5rem;
  }
  .sixthdingwei{
    position: absolute;
    top: 4.5rem;
    left: 8.3rem;
  }
  .seventhdingwei{
    position: absolute;
    top: 4rem;
    left: 10rem;
  }
}
@media (min-width: 1700px){
    
  .map{
    width: 15.67rem;
  }
  .firstdingwei{
    position: absolute;
    top: 1.5rem;
    left: 3.5rem;
  }
  .seconddingwei{
    position: absolute;
    top: 0rem;
    left: 13rem;
  }
  .thirddingwei{
    position: absolute;
    top: 2rem;
    left: 10rem;
  }
  .fourthdingwei{
    position: absolute;
    top: 5.5rem;
    left: 6.5rem;
  }
  .fifthdingwei{
    position: absolute;
    top:8rem;
    left: 8rem;
  }
  .sixthdingwei{
    position: absolute;
    top: 6.5rem;
    left: 9.8rem;
  }
  .seventhdingwei{
    position: absolute;
    top: 5rem;
    left: 12rem;
  }
} */
@media (min-width: 1400px) {
  .sanjia {
    width: 8rem;
  }
}
@media (min-width: 1500px) {
  .sanjia {
    width: 7rem;
  }
}
@media (min-width: 1500px) {
  .dingweistyle {
    width: 1.6rem;
  }
  .mapdetails {
    margin-left: 3rem;
    margin-top: -0.5rem;
  }

  .dingweitext {
    width: 1.6rem;
    height: 1.6rem;
    left: 0rem;
    top: 0.35rem;
  }
  .map {
    width: 9rem;
  }
  .dingweitext p {
    font-size: 14px;
    cursor: pointer;
  }
  .firstdingwei {
    position: absolute;
    top: 0.5rem;
    left: 1.9rem;
  }
  .seconddingwei {
    position: absolute;
    top: -0.7rem;
    left: 7.5rem;
  }
  .thirddingwei {
    position: absolute;
    top: 0.9rem;
    left: 5.9rem;
  }
  .fourthdingwei {
    position: absolute;
    top: 3rem;
    left: 3.7rem;
  }
  .fifthdingwei {
    position: absolute;
    top: 4.5rem;
    left: 4.6rem;
  }
  .sixthdingwei {
    position: absolute;
    top: 3.3rem;
    left: 5.7rem;
  }
  .seventhdingwei {
    position: absolute;
    top: 3rem;
    left: 7.2rem;
  }
}
.gsjj {
  display: flex;
  flex-direction: column;
}
.gsjjleftbox {
  width: 100%;
}
.bigimg {
  position: relative;
  width: 90%;
}
@media (min-width: 992px) {
  .gsjj {
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
  }
  .mapdetails {
    margin-left: 3rem;
    margin-top: -0.3rem;
  }
  .righttext {
    padding: 0;
  }
  .bigimg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .smallimg {
    display: block;
    margin-top: -1.55rem;
    width: 4.94rem;
    margin-left: 0;
  }
  .gsjjleftbox {
    margin-top: -0.6rem;
    width: 50%;
  }
  .righttext {
    text-align: start;
    width: 45%;
  }
  .bianzu {
    /* width: 2rem; */
    display: block;
    -webkit-transition: all 0.8s;
    -o-transition: all 0.8s;
    transition: all 0.8s;
  }
  .righttitle {
    font-size: 0.5rem;
    font-weight: 600;
  }

  .firsttitle {
    margin-top: 150px;
    margin-left: 15%;
    color: white;
    text-align: end;
    padding: 0;
  }

  .sytext1 {
    color: white;
    font-size: 0.72rem;
    font-weight: 600;
    margin-top: 0;
  }
  .sytext2 {
    color: white;
    font-size: 0.36rem;
    margin-top: 0.3rem;
  }
  .sytext3 {
    margin-top: 0.3rem;
  }
  .liucheng {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }
  .smallliucheng {
    display: none;
  }
  .mapstyle {
    padding: 1.2rem;
    margin-top: 9rem;
  }
  .maptitle p:nth-of-type(1) {
    font-size: 0.6rem;
    font-weight: bolder;
  }
  .maptitle p:nth-of-type(2) {
    font-size: 0.3rem;
    color: #9b9ea7;
    margin-top: 0.2rem;
  }
  .mapdetails {
    position: relative;
    display: block;
  }
  .smallmap {
    display: none;
  }
  .rightclass {
    display: block;
    margin-top: -8rem;
  }
  .swiperstyle {
    display: block;
  }
  .totalbottom-top-text1 {
    font-size: 0.8rem;
    bottom: 0.66rem;
    color: white;
  }
  .totalbottom-top-text2 {
    font-size: 0.6rem;
    font-weight: bolder;
    bottom: 1rem;
  }
  .bottomdown {
    width: 100%;

    overflow: auto;
    margin-top: -1px;
  }
  .bottomtext-bottom {
    font-size: 12px;
    text-align: end;
    padding: 0 5%;
    color: white;
    margin-bottom: 7px;
    opacity: 70%;
  }
  .bottomtext-top {
    font-size: 0.2rem;
    margin-bottom: 10px;
  }
  .bottomtext-bottom {
    /* font-size: .14rem; */
  }
  .bottom-box {
    width: 25%;
    padding: 0 1.5%;
  }
  .is-show {
    display: block;
  }
  .downbottom {
    margin-top: 0.9rem;
  }
  .newleft1 {
    z-index: 10;
    margin-left: 1.5rem;
    text-align: start;
  }
  .noswiper {
    display: none;
  }
  .chanpin li {
    list-style: disc;
  }
  .bottomsstyle {
    width: 2.8rem;
    height: 0.6rem;
    background-color: white;
    border-radius: 0.34rem;
    color: #35b5d3;
    line-height: 0.6rem;
    font-size: 0.24rem;
    /* margin-left: 2.66rem; */
    margin: 0 auto;
  }
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 10px !important;
  }
  .bottomtext {
    position: absolute;
    bottom: 0;
    width: 87%;
    font-weight: 600;
    margin-bottom: 14px;
  }
  .bottompadding {
    padding: 0 0.9rem;
  }
}
