.page1 {
  background-image: url('../../../images/number/pewPage1bg.jpg');
}
.page2 .left {
  background: url('../../../images/home/pew.png') center no-repeat;
  background-size: 120%;
}
.page2 .right .content {
  right: auto;
  left: 0;
  padding-right: 0.8rem;
}
.page2 .right .content .title {
  width: 2.4rem;
  left: auto;
  right: 0.57rem;
  top: -0.5rem;
}
.page3 {
  height: 27rem;
}
.page3 ul li {
  margin: 0 0.4rem;
}
.page3 ul li:nth-of-type(1) {
  background-image: url('../../../images/number/pew1.png');
}
.page3 ul li:nth-of-type(2) {
  background-image: url('../../../images/number/pew2.png');
}
.page3 ul li:nth-of-type(3) {
  background-image: url('../../../images/number/pew3.png');
}
.page3 ul li:nth-of-type(4) {
  background-image: url('../../../images/number/pew4.png');
}

@media (min-width: 992px) {
  .page3 {
    height: 8rem;
  }
}
