.container {
  height: 80vh;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.page1 {
  background-image: url('../../../images/number/page1bg.jpg');
}
.page1 .title {
  color: #fff;
  position: absolute;
  top: 32%;
  left: 15%;
}
.page1 .title h2 {
  font-size: 0.6rem;
  font-weight: 500;
}
.page1 .title p {
  font-size: 0.3rem;
  margin-top: 0.2rem;
  opacity: 0.8;
}
.page1 .tips {
  width: 100%;
  height: 3rem;
  color: #fff;
  position: absolute;
  bottom: 0;
  padding: 0.4rem 0.8rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background: linear-gradient(129deg, #3296fa75 0%, #38c9ba75 100%);
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.page1 .tips p {
  width: 100%;
  opacity: 0.8;
  font-size: 0.2rem;
}
.page2 {
  width: 100%;
  overflow: hidden;
  height: auto;
  position: relative;
}
.page2 > div {
  height: 6rem;
}
.page2 .left {
  background: url('../../../images/home/dtx-na.png') center no-repeat;
  background-size: contain;
}
.page2 .right {
  position: relative;
}
.page2 .right .content {
  width: 90%;
  padding: 0.84rem 1.2rem;
  padding-right: 0;
  background-color: rgba(237, 249, 250, 1);
  font-size: 0.24rem;
  color: #676c7a;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}
.page2 .right .content .title {
  position: absolute;
  top: -0.4rem;
  left: -0.3rem;
  width: 1.56rem;
  height: 0.9rem;

  color: #040c23;
  font-size: 0.6rem;

  font-weight: 500;
}
.page2 .right .content .title i {
  z-index: 1;
  content: '';
  width: 100%;
  height: 0.4rem;
  background-color: rgba(53, 181, 211, 1);
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0.7;
}
.page2 .right .content .title p {
  position: absolute;
  z-index: 2;
  text-align: center;
  width: 100%;
}
.page2 .icon1 {
  width: 0.76rem;
  position: absolute;
  top: 0.75rem;
  left: 1.49rem;
}
.page2 .icon2 {
  width: 1.54rem;
  position: absolute;
  top: 0.74rem;
  right: 0%;
  transform: translateX(60%);
}
.page2 .icon3 {
  width: 0.72rem;
  position: absolute;
  bottom: 1.29rem;
  /* left: 1.49rem; */
}
.page3 {
  background-image: url('../../../images/number/page3bg.jpg');
  background-size: 100% 3rem;
  background-position: 0 0;
  height: 32rem;
}
.page3 .title {
  color: #fff;
  position: absolute;
  top: 1.1rem;
  left: 50%;
  transform: translate(-50%, 0);
}
.page3 .title h2 {
  font-size: 0.6rem;
  font-weight: 500;
  white-space: nowrap;
}
.page3 .content {
  position: absolute;
  top: 2.4rem;
  left: 50%;
  transform: translateX(-50%);
}
.page3 ul li {
  height: 5.5rem;
  width: 4rem;
  font-size: 0.18rem;
  background-size: contain;
  position: relative;
}
.page3 ul li:nth-of-type(1) {
  background-image: url('../../../images/number/dtx1.png');
}
.page3 ul li:nth-of-type(2) {
  background-image: url('../../../images/number/dtx2.png');
}
.page3 ul li:nth-of-type(3) {
  background-image: url('../../../images/number/dtx3.png');
}
.page3 ul li:nth-of-type(4) {
  background-image: url('../../../images/number/dtx4.png');
}
.page3 ul li:nth-of-type(5) {
  background-image: url('../../../images/number/dtx5.png');
}
.page3 .content li p {
  color: #040c23de;
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translate(-50%, 0%);
  white-space: nowrap;
}
.page3 .follow {
  position: absolute;
  bottom: 0.4rem;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;

  color: #676c7a;
  font-size: 0.14rem;
}
.page3 .follow img {
  width: 1rem;
}
.icon4 {
  position: absolute;
  left: 0.3rem;
  bottom: 4rem;
  width: 1.4rem;
}
.icon5 {
  position: absolute;
  right: 1.6rem;
  bottom: 1.7rem;
  width: 0.72rem;
}
@media (min-width: 992px) {
  .page1 .tips {
    width: calc(100% - 2rem);
    height: 2rem;
    margin: 0 1rem;
  }
  .page2 > div {
    height: 80vh;
  }
  .page3 {
    height: 8rem;
  }
  .page3 .content {
    display: flex;
  }
  .page3 .content li {
    height: 3.7rem;
    width: 2.7rem;
  }
}
