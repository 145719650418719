.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  z-index: 500;
  background: transparent;
  overflow: hidden;
}
.navbar-container {
  padding: 0 15px;
  transition: all 0.6s;
}
.navbar-container::before,
.navbar-container::after {
  display: table;
  content: ' ';
}
.navbar-container::after {
  clear: both;
}
.navbar {
  height: 50px;
}
.navbar .logo {
  float: left;
  vertical-align: middle;
  width: 180px;
  height: 50px;
  background: url(../../images/logo.png) no-repeat center center;
  background-size: 180px auto;
  transition: all 0.5s ease 0s;
}
.navbar .logo h1 {
  margin: 0;
  font-size: 18px;
  line-height: 30px;
  float: left;
  margin-left: 10px;
}
.navbar-togger {
  float: right;
  display: block;
}
.navbar-togger {
  float: right;
  background: transparent;
  border: none;
  padding: 17px 15px;
  display: inline-block;
  margin-right: -15px;
  visibility: visible;
  transition: transform 0.44s 0.2s cubic-bezier(0.04, 0.04, 0.12, 0.96),
    opacity 0.28s 0.36s cubic-bezier(0.52, 0.16, 0.24, 1),
    -webkit-transform 0.44s 0.2s cubic-bezier(0.04, 0.04, 0.12, 0.96);
}
.navbar-togger .icon_bar {
  width: 20px;
  height: 2px;
  background-color: #fff;
  opacity: 1;
  transition: all 0.4s ease-out;
}
.navbar-togger .icon_bar:nth-of-type(2) {
  margin: 5px 0;
}
.open-menu .navbar-togger .icon_bar:nth-of-type(1) {
  transform: translate(0, 7px) rotate(45deg);
}
.open-menu .navbar-togger .icon_bar:nth-of-type(2) {
  opacity: 0;
  transform: translateX(5px);
}
.open-menu .navbar-togger .icon_bar:nth-of-type(3) {
  transform: translate(0, -7px) rotate(-45deg);
}
.nav-list li {
  cursor: pointer;
}
.nav-list li a {
  display: block;
}
.header.nav-fiex {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06);
}
.header.nav-fiex .navbar-container {
  background: #fff;
}
.header.nav-fiex .navbar-container .navbar-togger .icon_bar {
  background: #333;
}
.header.open-menu .navbar .logo,
.header.nav-fiex .navbar-container .navbar .logo {
  background-image: url(../../images/logo_blak.png);
}
.header.open-menu .navbar .logo,
.header.nav-fiex .navbar-container .navbar .whitelogo {
  background-image: url(../../images/whitelogo.png);
}
.header.nav-fiex .navbar-container .nav-list li a {
  color: inherit;
}
.header.nav-fiex .navbar-container .nav-list li a span::after {
  background: #333;
}
@media (min-width: 992px) {
  .header {
    height: 70px;
  }
  .navbar-container {
    padding: 0 40px;
  }
  .navbar {
    float: left;
    height: 60px;
  }
  .navbar-togger {
    display: none;
  }
  .navbar .logo {
    width: 234px;
    height: 60px;
    background-size: 234px auto;
  }
  .nav-menu {
    float: right;
  }
  .nav-list {
    float: left;
  }
  .nav-list li {
    float: left;
  }
  .nav-list li a {
    font-size: 15px;
    line-height: 70px;
    padding: 0 28px;
    color: #fff;
    opacity: 0.7;
  }
  .nav-list li a span {
    display: block;
    position: relative;
  }
  .nav-list li a span::after {
    content: '';
    width: 0;
    height: 6px;
    background: #fff;
    border-radius: 5px;
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    transition: all 0.4s;
  }
  .nav-list li.active {
    font-weight: 800;
  }
  .nav-list li.active a {
    opacity: 1;
  }
  .nav-list li.active a span::after,
  .nav-list li a:hover span::after {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .header {
    transition: background 0.44s 0.2s cubic-bezier(0.52, 0.16, 0.24, 1), height 0.56s cubic-bezier(0.52, 0.16, 0.24, 1);
  }
  .header.open-menu {
    height: 100%;
    background: #fff;
    transition: background 0.36s cubic-bezier(0.32, 0.08, 0.24, 1), height 0.56s cubic-bezier(0.52, 0.16, 0.24, 1);
  }
  .header.open-menu .navbar-togger .icon_bar {
    background: #333;
  }
  .nav-menu {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 620;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: auto;
    visibility: hidden;
    transition: visibility 0s linear 1s;
  }
  .header.open-menu .nav-menu {
    visibility: visible;
    transition-delay: 0s;
  }
  .nav-list > li {
    border-bottom: 1px solid #e5e5e5;
    opacity: 0;
    pointer-events: none;
  }
  .header.open-menu .nav-list > li,
  .header.open-menu .nav-pane {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .nav-list li a {
    font-size: 16px;
    line-height: 46px;
  }
  .nav-list > li:nth-child(1) {
    transform: translateY(-44px);
    transition: opacity 0.33s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.15s,
      transform 0.46s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.1s,
      -webkit-transform 0.46s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.1s;
  }
  .nav-list > li:nth-child(2) {
    transform: translateY(-40px);
    transition: opacity 0.29s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.13s,
      transform 0.45s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.095s,
      -webkit-transform 0.45s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.095s;
  }
  .nav-list > li:nth-child(3) {
    transform: translateY(-36px);
    transition: opacity 0.26s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.11s,
      transform 0.43s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.082s,
      -webkit-transform 0.43s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.082s;
  }
  .nav-list > li:nth-child(4) {
    transform: translateY(-32px);
    transition: opacity 0.23s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.09s,
      transform 0.42s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.069s,
      -webkit-transform 0.42s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.069s;
  }
  .nav-list > li:nth-child(5) {
    transform: translateY(-28px);
    transition: opacity 0.22s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.07s,
      transform 0.41s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.056s,
      -webkit-transform 0.41s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.056s;
  }
}
.fixing-nail {
  position: fixed;
  top: 70vh;
  right: 20px;
  width: 100px;
  height: 100px;
  cursor: pointer;
  z-index: 999;
}
.noshow {
  display: none;
}
.showbottom {
  display: block;
}
