.contact-us-item .new-text{
text-align: start;
font-size: .3rem;
padding: 0 .5rem;
font-weight: 500;
word-break: break-all;
text-overflow: ellipsis;
overflow: hidden;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
}

.new-des{

  font-size: .18rem;
  color: #676C7A;
  margin-top: .2rem;
  text-align: start;
padding: 0 .5rem;
word-break: break-all;
text-overflow: ellipsis;
overflow: hidden;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}
.zixunback1{
  /* height: 100vh; */
  overflow: hidden;
}
.detailstitle{
  font-weight: 600;
  text-align: center;
  font-size: 20px;
}
.titletime{
  text-align: center;
  margin-top: 10px;
}
.titlefrom{
  text-align: center;
  margin-top: 10px;
}
.detailtext{
  text-indent:30px;
}
.first-detals-text{
  margin-top: 20px;
}
.detial-img-box{
  text-align: center;
  width: 100%;
  margin: 20px 0;
}
.detals-img{
  width: 100%;
}

.detail-back{
  text-align: center;
  margin:40px 5px;

}
.detail-next{
  text-align: center;
}
.detail-back-botton{
  width: 25%;
  border: 1px solid  #E5E6E9;
  border-radius: 5px;
  padding: 2px;
  color: #676C7A;
  margin: 0 auto;
  margin-bottom: 10px;
}
.detail-back-botton:hover{
  border-color: #35B5D3;
  color: #35B5D3;
}
.detail-next-botton{
  width: 25%;
  padding: 2px;
  border: 1px solid  #E5E6E9;
  border-radius: 5px;
  color: #676C7A;
  margin: 0 auto;
}
.detail-next-botton:hover{
  border-color: #35B5D3;
  color: #35B5D3;
}
.zixuntext{
  width: 100%;
  position: absolute;
  top: 300px;
  text-align: center;
  color: white;
  -webkit-animation: textLeft 1s linear ;
  animation: textLeft 1s linear ;
}
.zixuntext1{
  font-size: 40px;
}
.zixuntext2{
  font-size: 30px;
}
.newheight{
  height: 5rem;
}
.detailpadiing{
  padding:20px;
}
.totaldetailstyle{
  padding:0 15px
}

@media (min-width: 992px) {
  .detailpadiing{
    padding:.2rem .5rem;
  }
  .totaldetailstyle{
    padding: 0 100px;
  }
  .bottomline:hover{
    /* text-decoration: underline; */
    color: #35B5D3;
  }
  .zixunback1{
  width: 100vw;
   height: 80vh 
  }
  .zixuntext{
    position: absolute;
    top: 4.5rem;
    left: 1.5rem;
    width: 5rem;
    color: white;
    animation: textLeft 1s linear ;
  }
  .zixuntext1{
    font-size:.7rem;
    font-weight: bold;
  }
  .zixuntext2{
    font-size:.3rem;
    text-align: end;
    margin-top: 20px;
  }
  .detailstitle{
    font-weight: 600;
    text-align: center;
    font-size: .4rem;
    margin-top: .5rem;
  }
  .titletime{
    width: 45%;
    text-align: end;
    margin-top: 5px;
    float: left;
    padding: 0 10% 0 0;
  }
  .titlefrom{
    width: 45%;
    text-align: start;
    margin-top: 5px;
    float: right;
  }
  .fromtime{
    margin-top: .5rem;
    font-size: .2rem;
    color: #9B9EA7;
  }
  .first-detals-text{
    margin-top: 1.3rem;
   
  }
  .detailtext{
    font-size: .22rem;
    line-height: 50px;
  }
 .detals-img{
  width: 100%;

 }
 .detail-next-botton{
  float: right;
  width: 1.5rem;
  padding: .09rem;
  border: 1px solid ;
  margin-left: 2rem;
}
.detail-back-botton{
  float: right;
  width: 1.5rem;
  padding: .09rem;
  border: 1px solid ;
  margin-left: .5rem;
}
}
@media (min-width: 1400px){
  .zixuntext{
    position: absolute;
    top: 3.5rem;
    left: 1.5rem;
    width: 5rem;
    color: white;
    animation: textLeft 1s linear ;
  }
}
@media (min-width: 1500px){
  .detals-img{
    width: 50%;
   }
 
    
  
  
}