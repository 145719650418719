.section-cases {
  height: 100vh;
  background: url('../../images/cases/cases_bg.jpg') no-repeat right center #000;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
@keyframes earthR {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-360deg);
  }
}
.earth-box {
  width: 62%;
  height: 100%;
  position: absolute;
  left: -30%;
  top: 0;
  z-index: 1;
}
.earth-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  background: url(../../images//cases/earth_01.png) no-repeat center center;
  background-size: contain;
  animation: earthR 50s linear infinite;
}
.earth-mark {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  background: url(../../images//cases/earth_02.png) no-repeat center center;
  background-size: contain;
}
.earth-light {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 4;
  background: url(../../images/cases/earth_03.png) no-repeat center center;
  background-size: contain;
}
.cases-footer {
  position: relative;
  z-index: 2;
}
.cases-container {
  margin-top: 100px;
  flex: 1;
  overflow: hidden;
  position: relative;
}
.development-view {
  width: 23%;
  position: absolute;
  top: 1rem;
  right: 100px;
  /* bottom: 0rem; */
  z-index: 2;
  overflow: hidden;
}
@media (min-width: 1500px) {
  .development-view {
    width: 23%;
    position: absolute;
    top: 1rem;
    right: 100px;
    /* bottom: 0rem; */
    z-index: 2;
    overflow: hidden;
  }
}

.development-view .swiper-container {
  height: 100%;
}
.dev-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.dev-title {
  position: relative;
  text-align: right;
  margin-right: 2rem;
  /* margin-top: .8rem; */
}
.dev-title .m-cn {
  font-size: 0.5rem;
  font-weight: 600;

  color: #fff;
  position: relative;
  z-index: 1;
}
.dev-title .m-en {
  font-size: 0.6rem;
  line-height: 1;
  color: rgba(255, 255, 255, 0.1);
  font-weight: 600;
  text-align: center;
  position: absolute;
  right: 0.22rem;
  top: 0.3rem;
  z-index: 2;
}
.dev-desc {
  /* margin-top: .9rem; */
  background: rgba(255, 255, 255, 0.09);
  font-size: 0.18rem;
  color: #fff;
  padding: 0.4rem 0.36rem;
  flex: 1;
  overflow: hidden;
  /* border-radius: 10px; */
  line-height: 0.26rem;
  height: 100%;
}
.development-page {
  width: 60%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  /* overflow: hidden; */
}
.development-page .swiper-container {
  height: 100%;
}
.development-view .swiper-slide {
  height: auto;
}
.development-page .dev-page {
  width: 100%;
  height: 1rem;
  display: block;
  margin: 0;
  background: transparent;
  border-radius: 0;
  animation-name: none;
  opacity: 1;
  position: relative;
  opacity: 0.6;
  transform: scale(0.6);
  transition: all 0.6s;
}
.development-page .dev-dot {
  visibility: hidden;
  width: 0.42rem;
  height: 0.42rem;
  border: 0.04rem solid #fff;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -0.21rem;
  margin-top: -0.21rem;
}
.development-page .dev-date {
  font-size: 0.44rem;
  line-height: 0.66rem;
  color: #fff;
  position: absolute;
  top: 50%;
  right: 56%;
  margin-top: -0.33rem;
  margin-right: 0.45rem;
}
.development-page .dev-name {
  font-size: 0.35rem;
  line-height: 0.66rem;
  font-weight: 500;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 45%;
  margin-top: -0.33rem;
  margin-left: 0.45rem;
  white-space: nowrap;
}
.development-page .swiper-slide-active .dev-page {
  transform: scale(1);
  opacity: 1;
}
.swiper-slide-active {
  opacity: 1 !important;
}
