.footer {
  background: linear-gradient(133deg, #38c9ba 0%, #3296fa 100%);
  font-size: 16px;
  color: rgba(255, 255, 255, 60%);
  padding: 0.4rem 0;
  text-align: center;
  height: 1rem;
}
.newfooter {
  background: rgba(0, 0, 0, 0.08);
  font-size: 16px;
  color: #fff;
  padding: 0.4rem 0;
  text-align: center;
  /* opacity: .6; */
}
.footer.transparent {
  background: rgba(0, 0, 0, 0.15);
}
.downline1 :hover {
  text-decoration: underline;
}

.footersize {
  font-size: 0.1rem;
}
